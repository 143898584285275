<template>
	<main class="char">
		<vue-title title="HTML Codes for Unicode Characters"></vue-title>
		<div class="container">
			<div class="row mt-3">
				<div class="col-xl-8 pb-5">
					<h1>HTML Keyboard <small>Online</small></h1>
					<div class="alert alert-info my-5">HTML Keyboard lets you browse the unicode characters and make selections by clicking on them. The selected characters will be shown in the text box below the table as HTML codes in either decimal or hexadecimal format.</div>
					<div id="html-keyboard">
						<div class="row">
							<div class="col-sm pb-3">
								<select v-model="start" class="form-control">
									<option v-for="option in gotoOptions" :key="option.value" :value="option.value">
										{{ option.text }}
									</option>
								</select>
							</div>
							<div class="col-sm-auto pb-3">
								<button class="btn btn-primary mr-1" @click="prev" :disabled="start < blockSize">Prev</button>
								<button class="btn btn-primary" @click="next">Next</button>
							</div>
						</div>
						<table class="table table-sm table-bordered table-striped text-center">
							<tbody>
								<tr v-for="i in numRows" :key="i">
									<td v-for="j in numCols" :key="cid(i, j)" @click="add(cid(i, j))" v-html="cidref(i, j)"></td>
								</tr>
							</tbody>
						</table>
						<div class="row">
							<div class="col">
								<div class="input-group">
									<textarea v-model="selectedChars" id="selected-chars" class="form-control" rows="2" cols="50" readonly></textarea>
									<div class="input-group-append">
										<span class="input-group-text" v-html="preview" id="selected-char-preview"></span>
									</div>
								</div>
								<p class="small text-info">The characters that you have selected are shown here.</p>
							</div>
							<div class="col-auto pl-4">
								<div class="custom-control custom-radio mt-1">
									<input class="custom-control-input" type="radio" id="dec" value="dec" v-model="outputType">
									<label class="custom-control-label" for="dec">Dec</label>
								</div>
								<div class="custom-control custom-radio mr-3">
									<input class="custom-control-input" type="radio" id="hex" value="hex" v-model="outputType">
									<label class="custom-control-label" for="hex">Hex</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col pb-3">
								<button class="btn btn-outline-secondary mr-1" @click="clear" :disabled="! selectedChars">Clear</button>
								<button class="btn btn-outline-primary" type="button" title="Copy" v-clipboard:copy="selectedChars" :disabled="! selectedChars">Copy</button>
							</div>
							<div class="col-auto pb-3">
								<button class="btn btn-primary mr-1" @click="prev" :disabled="start < blockSize">Prev</button>
								<button class="btn btn-primary" @click="next">Next</button><br>
								<small class="text-muted">(or use arrow keys)</small>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-4 pb-5">
					<div id="sidebar-tabs">
						<ul class="nav nav-tabs">
							<li class="nav-item">
								<a class="nav-link active" id="navlink1" href="#" @click.prevent="change(1)">What is "Unicode"?</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" id="navlink2" href="#" @click.prevent="change(2)">Compatibility</a>
							</li>
						</ul>
						<div class="tab-content mt-4 small">
							<div class="tab-pane fade show active" id="tabpane1">
								<p><strong>Unicode</strong> is a computing industry standard for the consistent encoding, representation, and handling of text expressed in most of the world's writing systems. The standard is maintained by the <a href="https://unicode.org/" target="_blank" rel="noopener noreferrer">Unicode Consortium</a>.</p>
								<p><strong>UTF-8</strong> is a variable width character encoding capable of encoding all 1,112,064 valid code points in Unicode using one to four 8-bit bytes. The encoding is defined by the Unicode standard. The name is derived from Unicode (or Universal Coded Character Set) Transformation Format – 8-bit.</p>
							</div>
							<div class="tab-pane fade" id="tabpane2">
								<p>Make sure that you check whether the character code which you selected is supported in other browsers. There are some character codes that will not render properly (or at all) in some browsers, especially in older versions.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import changeTab from '../_js/changeTab.js';

export default {
	name: "char",
	data: function() {
		return {
			start: 0,
			selectedChars: '',
			outputType: 'dec',
			numRows: 16,
			numCols: 16,
			preview: '&nbsp;',
			gotoOptions: [
				{ text: 'Basic Latin', value: 0 },
				{ text: 'Latin Extended - A', value: 256 },
				{ text: 'Latin Extended - B; IPA Extensions', value: 512 },
				{ text: 'Combining Diacritical Marks; Greek; Coptic', value: 768 },
				{ text: 'Cyrillic', value: 1024 },
				{ text: 'Cyrillic Supplementary; Armenian; Hebrew', value: 1280 },
				{ text: 'Arabic', value: 1536 },
				{ text: 'Syriac', value: 1792 },
				{ text: 'Thaana', value: 2048 },
				{ text: 'Devanagari; Bengali', value: 2304 },
				{ text: 'Gurmukhi; Gujarati', value: 2560 },
				{ text: 'Oriya; Tamil', value: 2816 },
				{ text: 'Telugu; Kannada', value: 3072 },
				{ text: 'Malayalam; Sinhala', value: 3328 },
				{ text: 'Thai; Lao', value: 3584 },
				{ text: 'Tibetan', value: 3840 },
				{ text: 'Myanmar; Georgian', value: 4096 },
				{ text: 'Hangul Jamo', value: 4352 },
				{ text: 'Ethiopic', value: 4608 },
				{ text: 'Cherokee', value: 4864 },
				{ text: 'Unified Canadian Aboriginal Syllabics - A', value: 5120 },
				{ text: 'Unified Canadian Aboriginal Syllabics - B', value: 5376 },
				{ text: 'Ogham; Runic', value: 5632 },
				{ text: 'Tagalog; Hanunoo; Buhid; Tagbanwa; Khmer', value: 5888 },
				{ text: 'Mongolian', value: 6144 },
				{ text: 'Limbu; Tai Le; Khmer Symbols', value: 6400 },
				{ text: 'Phonetic Extensions', value: 7424 },
				{ text: 'Latin Extended Additional', value: 7680 },
				{ text: 'Greek Extended', value: 7936 },
				{ text: 'General Punctuation', value: 8192 },
				{ text: 'Mathematical Operators', value: 8704 },
				{ text: 'Box Drawing; Block Elements; Geometric Shapes', value: 9472 },
				{ text: 'Miscellaneous Symbols', value: 9728 },
				{ text: 'Dingbats', value: 9984 },
				{ text: 'Braille Patterns', value: 10240 },
				{ text: 'Supplemental Arrows - B', value: 10496 },
				{ text: 'Supplemental Mathematical Operators', value: 10752 },
				{ text: 'Miscellaneous Symbols and Arrows', value: 11008},
				{ text: 'Old Italic; Gothic', value: 66304 },
				{ text: 'Musical Symbols', value: 119040 },
				{ text: 'Emoji', value: 127744 }
			]
		};
	},
	created: function () {
		window.addEventListener('keyup', this.keyboardNavigate);
	},
	computed: {
		blockSize: function () {
			return this.numRows * this.numCols;
		}
	},
	methods: {
		range: function (min, max) {
			var rArray = [],
				rj = 0;
			for (var ri = min; ri <= max; ri++) {
				rArray[rj] = ri;
				rj++;
			}
			return rArray;
		},
		cid: function (ii, jj) {
			return this.start + (ii - 1) * this.numCols + jj - 1;
		},
		cidref: function (ii, jj) {
			return '&#' + this.cid(ii, jj) + ';';
		},
		add: function (c) {
			if (this.outputType === 'dec') {
				this.selectedChars += '&#' + c + ';';
			} else {
				this.selectedChars += '&#x' + c.toString(16) + ';';
			}
			this.preview = '&#' + c + ';';
		},
		clear: function () {
			this.selectedChars = '';
			this.preview = '&nbsp;';
		},
		prev: function () {
			this.start -= this.blockSize;
		},
		next: function () {
			this.start += this.blockSize;
		},
		keyboardNavigate: function () {
			if ((event.which === 37) && (this.start >= this.blockSize)) {
				this.prev();
			}
			if (event.which === 39) {
				this.next();
			}
		},
		change: function(id) {
			changeTab.show(id);
		}
	}
}
</script>